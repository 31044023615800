import { useOutletContext } from 'react-router-dom';

import { RadioGroupHorizontal, RadioGroupVertical } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const RelationshipType = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const relationshipType = Content[landerId][versionId]?.relationshipType;

	const Orientation =
		relationshipType?.orientation === 'horizontal'
			? RadioGroupHorizontal
			: RadioGroupVertical;

	return (
		<Orientation
			options={relationshipType?.options ?? []}
			name={relationshipType?.name ?? ''}
		/>
	);
};
