import './style.scss';

import { format, subYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Spinner } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { Gender, OutletContext, Seeking } from '@/model';
import { Content } from '@/pages/content';
import { useRegister } from '@/service';
import {
	generateRandomPassword,
	generateRandomUsername,
	LocalStorage,
	LocalStorageItems,
} from '@/utility';

export const Success = () => {
	const { t } = useTranslation();
	const { versionId, landerId }: OutletContext = useOutletContext();
	const [searchParams] = useSearchParams();
	const { state } = useStateMachine({ updateFormActionLander });
	const click_id = searchParams.get('clickId') ?? '';
	const affiliate_id = searchParams.get('affiliate') ?? '';
	const source_id = searchParams.get('source') ?? '';
	const lander_id = LocalStorage.getItem(LocalStorageItems.LanderId);
	const successData = Content[landerId][versionId]?.success;

	const { username, password, gender, city, zipCode, email, age } =
		state.lander ?? {};
	const randomUsername = generateRandomUsername(email);
	const randomPassword = generateRandomPassword(7);

	const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
	const registerData = {
		click_id,
		affiliate_id,
		source_id,
		lander_id,
		username: username || randomUsername,
		password: password || randomPassword,
		password_confirmation: password || randomPassword,
		email,
		profile: {
			gender,
			seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			city,
			zip_code: zipCode,
			birthday,
		},
	};
	const { isError, refetch } = useRegister(registerData);

	return (
		<div
			className={`success__wrapper success__wrapper${landerId}-v${versionId} flex items-center justify-center`}
		>
			<div className="absolute top-[50%] -translate-y-1/2 md:static md:top-auto md:translate-y-0">
				{isError ? (
					<div className="flex justify-center items-center gap-0.5">
						<p
							className={`pandora__form--error pandora__form--error--v${versionId} !mt-0`}
						>
							{t('general.error')}
						</p>
						<button
							onClick={() => refetch()}
							className={`pandora__button__email--error pandora__button__email--error--v${versionId} underline cursor-pointer`}
						>
							{t('general.retry')}
						</button>
					</div>
				) : (
					<div className="flex flex-col items-center gap-5 p-5 success__wrapper--title">
						<h1>{successData?.title}</h1>
						<Spinner size={100} />
					</div>
				)}
			</div>
		</div>
	);
};
