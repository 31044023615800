// Version 1
import version1Bg from './version1/bg.png';
import version1GenderFemale from './version1/gender/female.png';
import version1GenderMale from './version1/gender/male.png';
import version1Host from './version1/host.png';
import version1LookingForMen from './version1/lookingFor/men.png';
import version1LookingForWomen from './version1/lookingFor/women.png';
import version1RelationshipTypeCasual from './version1/relationshipType/casual.png';
import version1RelationshipTypeCoupleOfDates from './version1/relationshipType/coupleOfDates.png';
import version1RelationshipTypeSeriousRelationship from './version1/relationshipType/seriousRelationship.png';
import version1WomanTypeCurvy from './version1/womanType/curvy.png';
import version1WomanTypeLarge from './version1/womanType/large.png';
import version1WomanTypeNormal from './version1/womanType/normal.png';

export const Lander15Version1 = {
	bg: version1Bg,
	gender: {
		female: version1GenderFemale,
		male: version1GenderMale,
	},
	host: version1Host,
	womanType: {
		normal: version1WomanTypeNormal,
		curvy: version1WomanTypeCurvy,
		large: version1WomanTypeLarge,
	},
	relationshipType: {
		casual: version1RelationshipTypeCasual,
		coupleOfDates: version1RelationshipTypeCoupleOfDates,
		seriousRelationship: version1RelationshipTypeSeriousRelationship,
	},
	lookingFor: {
		men: version1LookingForMen,
		women: version1LookingForWomen,
	},
};
