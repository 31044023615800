import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, Select } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { Content } from '@/pages/content';
import { HeightValidation } from '@/validations';

interface FormValues {
	height: string;
}

export const SelectHeight = () => {
	const { t } = useTranslation();
	const { handleNext, versionId, landerId }: OutletContext = useOutletContext();
	const selectHeight = Content[landerId][versionId]?.selectHeight;
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm<FormValues>({
		resolver: HeightValidation(t),
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onChange = (value: string) => {
		clearErrors();
		setValue('height', value);
	};

	const onSubmit = (data: FormValues) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<div
			className={`selectHeight__wrapper selectHeight__wrapper${landerId}-v${versionId}`}
		>
			<h1 className="selectHeight__wrapper--title">{selectHeight?.title}</h1>
			<form
				className="selectHeight__wrapper--form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Select
					{...register(selectHeight?.name as keyof FormValues)}
					options={selectHeight?.options}
					placeholder={selectHeight?.placeholder ?? ''}
					onChange={onChange}
				/>
				<Expand expanded={Boolean(errors?.height)}>
					<p
						className={`pandora__form--error pandora__form--error--v${versionId}`}
					>
						{errors.height?.message}
					</p>
				</Expand>
				<Button>{t('general.next')}</Button>
			</form>
		</div>
	);
};
