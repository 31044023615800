import { yupResolver } from '@hookform/resolvers/yup';
import { TOptions } from 'i18next';
import * as yup from 'yup';

export function getValidationSchema(
	t: (key: string, options?: TOptions | string) => string
) {
	return yup.object().shape({
		username: yup
			.string()
			.required(t('general.required'))
			.min(6, t('username.min'))
			.max(16, t('username.max'))
			.test('special characters', t('username.noSpecialChars'), (val) =>
				/^[a-zA-Z0-9_-]+$/.test(val)
			),
	});
}

export const UsernameValidation = (t: (key: string) => string) =>
	yupResolver(getValidationSchema(t));
