import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../button';
import { Expand } from '../Expand';
import { Google } from '../icons';

interface IGoogleSSO {
	handleGoogleSSO: (email: string) => void;
}

export const GoogleSSO = ({ handleGoogleSSO }: IGoogleSSO) => {
	const { t } = useTranslation();
	const [error, setError] = useState<string>('');
	const login = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			try {
				const userDataResponse = await fetch(
					'https://www.googleapis.com/oauth2/v2/userinfo',
					{
						headers: {
							Authorization: `Bearer ${tokenResponse.access_token}`,
						},
					}
				);
				const userData = await userDataResponse.json();
				const userEmail = userData.email;
				handleGoogleSSO(userEmail);
			} catch (error) {
				setError(t('general.error'));
			}
		},
		onError: () => setError(t('general.error')),
	});

	return (
		<div className="google__sso__wrapper">
			<p className="mt-5 text-center google__sso__wrapper--divider">
				{t('general.or')}
			</p>
			<Button
				type="button"
				variant="outline"
				className="google__sso"
				onClick={() => login()}
			>
				<div className="flex gap-2.5 justify-center">
					<Google />
					{t('general.registerWithGoogle')}
				</div>
			</Button>
			<Expand expanded={Boolean(error)}>
				<p className="pandora__form--error">{error}</p>
			</Expand>
		</div>
	);
};
