import './style.scss';

import { StateMachineProvider } from 'little-state-machine';
import { Outlet } from 'react-router-dom';

import { FluidSlider, Header } from '@/components';

import { Content } from '../content';

interface ILander9 {
	percent: number;
	message: string;
	done: boolean;
	setDone: (done: boolean) => void;
	handleNext: () => void;
	versionId: string;
	landerId: string;
}

export const Lander9 = ({ handleNext, landerId, versionId }: ILander9) => {
	const lander = Content[landerId][versionId]?.lander;
	return (
		<div className="relative">
			<FluidSlider slides={lander?.slider ?? []} />
			<StateMachineProvider>
				<div className={`lander9__wrapper lander9__wrapper--v${versionId}`}>
					<Header img={lander?.logo ?? ''} landerId="9" versionId={versionId} />
					<div className={`lander9__wrapper__container`}>
						<div
							className={`lander9__wrapper__container__content lander9__wrapper__container__content--v${versionId}`}
						>
							<Outlet context={{ handleNext, landerId, versionId }} />
						</div>
					</div>
				</div>
			</StateMachineProvider>
		</div>
	);
};
