import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputPassword } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { PasswordValidation } from '@/validations';

interface PasswordForm {
	password: string;
}

export const Password = () => {
	const { t } = useTranslation();
	const { handleNext, versionId }: OutletContext = useOutletContext();
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<PasswordForm>({ resolver: PasswordValidation(t) });
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onSubmit = (data: PasswordForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
			<div className="w-full">
				<InputPassword
					{...register('password')}
					placeholder={t('password.placeholder')}
				/>
				<Expand expanded={Boolean(errors.password)}>
					<p
						className={`pandora__form--error pandora__form--error--v${versionId}`}
					>
						{errors.password?.message}
					</p>
				</Expand>
			</div>
			<Button>{t('general.next')}</Button>
		</form>
	);
};
