// Version 1
import version1Logo from './version1/logo.svg';
import version1Slider1 from './version1/slider/silder1.png';
import version1Slider2 from './version1/slider/silder2.png';
import version1Slider3 from './version1/slider/silder3.png';

export const Lander9Version1 = {
	logo: version1Logo,
	slider: [version1Slider1, version1Slider2, version1Slider3],
};
