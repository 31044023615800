import { yupResolver } from '@hookform/resolvers/yup';
import { TOptions } from 'i18next';
import * as yup from 'yup';

export function getValidationSchema(
	t: (key: string, options?: TOptions | string) => string
) {
	return yup.object().shape({
		age: yup
			.string()
			.required(t('general.required'))
			.min(1, t('age.min'))
			.test('age', t('age.legalAge'), (value) => Number(value) >= 18)
			.test('age range', t('age.max'), (value) => Number(value) < 100),
	});
}

export const AgeValidation = (t: (key: string) => string) =>
	yupResolver(getValidationSchema(t));
