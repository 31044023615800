import { useOutletContext } from 'react-router-dom';

import { Prompt } from '@/components';
import { IPrompt } from '@/components/prompt/Prompt';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Pictures = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const pictures = Content[landerId][versionId]?.pictures ?? ({} as IPrompt);

	return (
		<Prompt
			image={pictures?.image}
			title={pictures?.title}
			acceptText={pictures?.acceptText}
			rejectText={pictures?.rejectText}
		/>
	);
};
