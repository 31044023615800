export const generateNumbers = (
	start: number,
	end: number,
	toString = false
) => {
	const result = Array.from(
		{ length: end - start + 1 },
		(_, index) => start + index
	);

	if (toString) {
		return result.map(String);
	}

	return result;
};
