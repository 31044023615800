export const generateRandomUsername = (email: string) => {
	// Extract the part before the "@" symbol
	let usernamePart = email?.split('@')[0];

	// Remove any part after the "+" symbol, if present
	if (usernamePart?.includes('+')) {
		usernamePart = usernamePart.split('+')[0];
	}

	// Replace invalid characters with underscores (or any allowed character)
	usernamePart = usernamePart?.replace(/[^a-zA-Z0-9-_]/g, '_');

	// Generate a random number
	const randomNumber = Math.floor(Math.random() * 9000) + 1000;
	const randomNumberString = randomNumber.toString();

	// Ensure the total length of the username is between 6 and 16 characters
	const minLength = 6;
	const maxLength = 16;

	if (usernamePart?.length + randomNumberString.length > maxLength) {
		// If the username is too long, truncate it to fit within the maxLength
		usernamePart = usernamePart.slice(0, maxLength - randomNumberString.length);
	} else if (usernamePart.length < minLength - randomNumberString.length) {
		// If the username is too short, pad it with extra characters or numbers
		const paddingLength =
			minLength - usernamePart.length - randomNumberString.length;
		usernamePart = usernamePart.padEnd(
			usernamePart.length + paddingLength,
			'_'
		); // Pad with '_'
	}

	// Combine the username part with the random number
	const randomUsername = `${usernamePart}${randomNumberString}`;

	return randomUsername;
};
