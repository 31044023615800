import { useOutletContext } from 'react-router-dom';

import { RadioGroupHorizontal } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Gender = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const gender = Content[landerId][versionId]?.gender;

	return (
		<RadioGroupHorizontal
			options={gender?.options ?? []}
			name={gender?.name ?? ''}
		/>
	);
};
