import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createStore } from 'little-state-machine';
import { RouterProvider } from 'react-router-dom';

import { router } from '@/router';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			networkMode: 'always',
		},
	},
});

createStore({
	lander: {
		age: '',
		city: '',
		gender: '',
		preference: '',
		womanType: '',
		zipCode: '',
		username: '',
		password: '',
		email: '',
		relationshipType: '',
		womanPreference: '',
		lookingFor: '',
		secret: '',
	},
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider
				clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID ?? ''}
			>
				<RouterProvider router={router} />
			</GoogleOAuthProvider>
		</QueryClientProvider>
	);
}

export default App;
