import { useOutletContext } from 'react-router-dom';

import { IPrompt, Prompt } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Fun = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const fun = Content[landerId][versionId]?.fun ?? ({} as IPrompt);

	return (
		<Prompt
			title={fun?.title}
			acceptText={fun?.acceptText}
			rejectText={fun?.rejectText}
			image={fun?.image}
		/>
	);
};
