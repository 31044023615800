import { useEffect } from 'react';

export const BackButtonScript = () => {
	useEffect(() => {
		document.body.addEventListener('click', function (event) {
			const target = (event.target as Element).closest('a');
			if (target && target.href) {
				event.preventDefault();
			}
		});
		function addRedirectEvents() {
			let touched = false;
			const chromeMatch = navigator.userAgent.match(/Chrome\/([0-9]+)/);
			const chromeVersion = chromeMatch ? Number(chromeMatch[1]) : null;

			if (chromeVersion && chromeVersion >= 75) {
				if (!touched) {
					touched = true;
					setTimeout(function () {
						updateHistory();
					}, 0);
				}
				document.addEventListener('DOMContentLoaded', function () {
					document.body.addEventListener('touchend', function (evt) {
						if (!touched) {
							touched = true;
							setTimeout(function () {
								updateHistory();
							}, 0);
						}
					});
					document.body.addEventListener('keydown', function () {
						if (!touched) {
							touched = true;
							setTimeout(function () {
								updateHistory();
							}, 0);
						}
					});
				});
			} else {
				setTimeout(function () {
					updateHistory();
				}, 0);
			}
		}

		function updateHistory() {
			history.replaceState(
				null,
				document.title,
				location.pathname + location.search + '#!/history'
			);
			history.pushState(
				null,
				document.title,
				location.pathname + location.search
			);

			window.addEventListener(
				'popstate',
				function () {
					if (location.hash === '' || location.hash === '#!/history') {
						history.replaceState(
							null,
							document.title,
							location.pathname + location.search
						);
						setTimeout(function () {
							const locationValue = window.location.search;
							const searchParams = new URLSearchParams(locationValue);
							const offerId = searchParams.get('offer_id');
							const sub1 = searchParams.get('sub1');
							const sub2 = searchParams.get('sub2');
							location.replace(`https://www.hoa44trk.com`);
							//   if (searchParams.get('affiliate_id') !== '') {
							//     const affiliateId = searchParams.get('affiliate_id');
							//     location.replace(
							//         `https://www.hoa44trk.com/cmp/85S851/6TGX69?sub1=${sub1}&sub2=${affiliateId}_${offerId}&sub3=${sub2}`
							//     )
							//   }
						}, 0);
					}
				},
				false
			);
		}

		addRedirectEvents();

		// Optional: Load an external script conditionally
		if (import.meta.env.VITE_BACK_BUTTON_SCRIPT === '1') {
			const existingScript = document.querySelector(
				'script[src="../utility/back-button-script.js"]'
			);

			if (!existingScript) {
				const script = document.createElement('script');
				script.src = '../utility/back-button-script.js';
				script.type = 'module';
				document.body.appendChild(script);
			}
		}
	});

	return null;
};
