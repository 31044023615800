import './style.scss';

import { useOutletContext } from 'react-router-dom';

import { Prompt } from '@/components';
import { IPrompt } from '@/components/prompt/Prompt';
import { OutletContext } from '@/model';
import { Content } from '@/pages/content';

export const Secret = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const secret = Content[landerId][versionId]?.secret ?? ({} as IPrompt);

	return (
		<Prompt
			image={secret?.image}
			title={secret?.title}
			overlayTitle={secret?.overlayTitle}
			acceptText={secret?.acceptText}
			rejectText={secret?.rejectText}
		/>
	);
};
