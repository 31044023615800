import { yupResolver } from '@hookform/resolvers/yup';
import { TOptions } from 'i18next';
import * as yup from 'yup';

export function getValidationSchema(
	t: (key: string, options?: TOptions | string) => string
) {
	return yup.object().shape({
		password: yup
			.string()
			.required(t('general.required'))
			.min(6, t('password.min'))
			.max(16, t('password.max')),
	});
}

export const PasswordValidation = (t: (key: string) => string) =>
	yupResolver(getValidationSchema(t));
