import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputNumber } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { AgeValidation } from '@/validations';

interface AgeForm {
	age: string;
}

export const SelectAge = () => {
	const { t } = useTranslation();
	const { handleNext, versionId, landerId }: OutletContext = useOutletContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<AgeForm>({
		resolver: AgeValidation(t),
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onSubmit = (data: AgeForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<div
			className={`selectAge__wrapper selectAge__wrapper${landerId}-v${versionId}`}
		>
			<h1 className="selectAge__wrapper--title">Your age:</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputNumber
					{...register('age')}
					placeholder={t('age.placeholder')}
					min="1"
				/>
				<Expand expanded={Boolean(errors?.age)}>
					<p
						className={`pandora__form--error pandora__form--error--v${versionId}`}
					>
						{errors.age?.message}
					</p>
				</Expand>
				<Button>{t('general.next')}</Button>
			</form>
		</div>
	);
};
