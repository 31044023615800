import { useOutletContext } from 'react-router-dom';

import { IPrompt, Prompt } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Old = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const old = Content[landerId][versionId]?.old ?? ({} as IPrompt);

	return (
		<Prompt
			acceptText={old?.acceptText}
			rejectText={old?.rejectText}
			title={old?.title}
		/>
	);
};
