import { Market } from '@/model';

import { config as configCP } from './casualPleasure';
import { config as configDK } from './dk';
import { config as configUS } from './us';

const market = import.meta.env.VITE_MARKET as Market;

const CONFIG = {
	dk: configDK,
	casualPleasure: configCP,
	us: configUS,
};

export const config = CONFIG[market];
