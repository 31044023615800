import './style.scss';

interface IHeader {
	img: string;
	landerId: string;
	versionId: string;
}

export const Header = ({ img, landerId, versionId }: IHeader) => {
	return (
		<div className={`header__wrapper header__wrapper${landerId}-v${versionId}`}>
			<img src={img} alt="logo" />
		</div>
	);
};
