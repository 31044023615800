import './style.scss';

import { AnimatePresence } from 'framer-motion';
import { StateMachineProvider } from 'little-state-machine';
import { Outlet } from 'react-router-dom';

import { AnimateTextTyping, Expand, ProgressBar } from '@/components';

import { Content } from '../content';

interface ILander1 {
	percent: number;
	message: string;
	done: boolean;
	setDone: (done: boolean) => void;
	handleNext: () => void;
	versionId: string;
	landerId: string;
}

export const Lander1 = ({
	percent,
	message,
	done,
	setDone,
	handleNext,
	landerId,
	versionId,
}: ILander1) => {
	const lander = Content[landerId][versionId]?.lander;

	return (
		<StateMachineProvider>
			<div className={`lander1__wrapper lander1__wrapper--v${versionId}`}>
				<img
					className={`lander1__wrapper__background--image`}
					src={lander?.bgImage}
					alt="background"
				/>
				<div className={`lander1__wrapper__container`}>
					<ProgressBar
						percent={percent}
						baseColor={lander?.progressBarBaseColor}
						progressColor={lander?.progressBarProgressColor}
						hidePercentage={lander?.hidePercentage}
					/>
					<div
						className={`lander1__wrapper__container__content lander1__wrapper__container__content--v${versionId}`}
					>
						<div className={`lander1__wrapper__container__content__host`}>
							<div
								className={`lander1__wrapper__container__content__host--image lander1__wrapper__container__content__host--image--v${versionId}`}
							>
								<img
									src={lander?.hostImage}
									alt="host"
									className={`lander1__wrapper__container__content__host--image--avatar`}
								/>
							</div>
							<div
								className={`lander1__wrapper__container__content__host--bubble lander1__wrapper__container__content__host--bubble--v${versionId}`}
							>
								<AnimatePresence mode="wait">
									{message && (
										<AnimateTextTyping
											key={message}
											text={message}
											setDone={setDone}
											cursorBlinkerColor={lander?.progressBarProgressColor}
										/>
									)}
								</AnimatePresence>
							</div>
						</div>
						<Expand expanded={done} key={message}>
							<Outlet context={{ handleNext, landerId, versionId }} />
						</Expand>
					</div>
				</div>
			</div>
		</StateMachineProvider>
	);
};
