import './style.scss';

import { EmblaOptionsType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import Fade from 'embla-carousel-fade';
import useEmblaCarousel from 'embla-carousel-react';

import { Spinner } from '@/components/spinner';

interface IFluidSlider {
	slides: string[];
}

export const FluidSlider = ({ slides }: IFluidSlider) => {
	const OPTIONS: EmblaOptionsType = {
		loop: true,
	};

	const [emblaRef] = useEmblaCarousel(OPTIONS, [Autoplay(), Fade()]);

	return (
		<div className="embla">
			<div className="embla__viewport" ref={emblaRef}>
				<div className="embla__container">
					{slides.length > 0 ? (
						slides?.map((slide) => (
							<div className="embla__slide" key={slide}>
								<img src={slide} />
							</div>
						))
					) : (
						<div className="min-h-[194px]">
							<Spinner />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
