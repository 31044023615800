import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputText } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { useGetCityByZipCode } from '@/service';
import { getZipCodeLength } from '@/utility';
import { ZipCodeValidation } from '@/validations';

interface ZipCodeForm {
	zipCode: string;
	city: string;
}

export const ZipCode = () => {
	const { t } = useTranslation();
	const { handleNext, versionId, landerId }: OutletContext = useOutletContext();
	const { mutate: getCities, isPending } = useGetCityByZipCode();
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<ZipCodeForm>({
		resolver: ZipCodeValidation(t),
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const [error, setError] = useState<string>('');
	const [disabled, setDisabled] = useState<boolean>(false);
	const zipCode = watch('zipCode');

	const onSubmit = (data: ZipCodeForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	const handleCities = useCallback(() => {
		setDisabled(false);
		setError('');
		if (zipCode?.length !== getZipCodeLength()) return;
		getCities(zipCode, {
			onSuccess: (response) => {
				if (response?.data?.length > 0) {
					setValue('city', response.data[0].city);
				} else {
					setDisabled(true);
					setError(t('zipCode.error'));
				}
			},
		});
	}, [getCities, setValue, t, zipCode]);

	useEffect(() => {
		handleCities();
	}, [zipCode, handleCities]);

	return (
		<div
			className={`zipCode__wrapper zipCode${landerId}__wrapper-v${versionId}`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full">
					<InputText
						{...register('zipCode')}
						placeholder={t('zipCode.placeholder')}
					/>
					<div className="hidden">
						<InputText {...register('city')} placeholder="" />
					</div>
					<Expand expanded={Boolean(errors.zipCode) && !error}>
						<p
							className={`pandora__form--error pandora__form--error--v${versionId}`}
						>
							{errors.zipCode?.message}
						</p>
					</Expand>
					<Expand expanded={Boolean(error)}>
						<p
							className={`pandora__form--error pandora__form--error--v${versionId}`}
						>
							{error}
						</p>
					</Expand>
				</div>
				<Button disabled={disabled} loading={isPending}>
					{t('general.next')}
				</Button>
			</form>
		</div>
	);
};
