import './style.scss';

import { format, subYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Button, Expand, GoogleSSO, InputEmail } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { Gender, OutletContext, Seeking } from '@/model';
import { useValidateUserEmail } from '@/service';
import {
	handleAuthReactivation,
	LocalStorage,
	LocalStorageItems,
} from '@/utility';
import { EmailValidation } from '@/validations';

interface EmailForm {
	email: string;
}

export const Email = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const click_id = searchParams.get('clickId') ?? '';
	const affiliate_id = searchParams.get('affiliate') ?? '';
	const source_id = searchParams.get('source') ?? '';
	const [error, setError] = useState<string>('');
	const { handleNext, landerId, versionId }: OutletContext = useOutletContext();
	const lander_id_base = `${landerId}.${versionId}`;
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<EmailForm>({
		resolver: EmailValidation(t),
	});

	const { actions, state } = useStateMachine({ updateFormActionLander });
	const { mutate: validateUserEmail, isPending } = useValidateUserEmail();

	const onSubmit = (data: EmailForm) => {
		const lander_id = `${lander_id_base}_typed_email`;
		LocalStorage.setItem(LocalStorageItems.LanderId, lander_id);
		LocalStorage.setItem(LocalStorageItems.Email, data.email);
		setError('');
		const { username, password, gender, city, zipCode, age } = state.lander;
		const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		const { email } = data;
		validateUserEmail(
			{
				email,
				lander_id,
				affiliate_id,
				source_id,
				click_id,
				username,
				password,
				password_confirmation: password,
				gender: gender as Gender,
				city,
				zip_code: zipCode,
				birthday,
				seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			},
			{
				onSuccess: (response) => {
					handleAuthReactivation(response, handleNext);
				},
				onError: (error) => {
					const emailError = error?.response?.data?.errors?.email;
					if (emailError) {
						setError(emailError[0]);
						return;
					}
					setError(t('general.error'));
				},
			}
		);
	};

	const handleGoogleSSO = (email: string) => {
		const lander_id = lander_id_base;
		LocalStorage.setItem(LocalStorageItems.LanderId, lander_id);
		LocalStorage.setItem(LocalStorageItems.Email, email);
		setError('');
		const { username, password, gender, city, zipCode, age } = state.lander;
		const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
		validateUserEmail(
			{
				email,
				lander_id,
				affiliate_id,
				source_id,
				click_id,
				username,
				password,
				password_confirmation: password,
				gender: gender as Gender,
				city,
				zip_code: zipCode,
				birthday,
				seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			},
			{
				onSuccess: (response) => {
					actions.updateFormActionLander({
						lander: { ...state.lander, email },
					});
					handleAuthReactivation(response, handleNext);
				},
				onError: (error, data) => {
					setValue('email', data?.email);
					const emailError = error?.response?.data?.errors?.email;
					if (emailError) {
						setError(emailError[0]);
						return;
					}
					setError(t('general.error'));
				},
			}
		);
	};

	return (
		<div className={`email__wrapper email__wrapper${landerId}-v${versionId}`}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full">
					<InputEmail
						{...register('email')}
						placeholder={t('email.placeholder')}
					/>
					<Expand expanded={Boolean(errors?.email) && !error}>
						<p
							className={`pandora__form--error pandora__form--error--v${versionId}`}
						>
							{errors?.email?.message}
						</p>
					</Expand>
					<Expand expanded={Boolean(error)}>
						<p
							className={`pandora__form--error pandora__form--error--v${versionId}`}
						>
							{error}
						</p>
					</Expand>
					<GoogleSSO handleGoogleSSO={handleGoogleSSO} />
				</div>
				<Button loading={isPending}>{t('general.next')}</Button>
			</form>
		</div>
	);
};
