import './style.scss';

import { useOutletContext } from 'react-router-dom';

import { Button } from '@/components';
import { OutletContext } from '@/model';

export interface IPrompt {
	title?: string;
	acceptText: string;
	rejectText: string;
	image?: string;
	overlayTitle?: string;
}

export const Prompt = ({
	image,
	title,
	acceptText,
	rejectText,
	overlayTitle,
}: IPrompt) => {
	const { handleNext, landerId, versionId }: OutletContext = useOutletContext();

	return (
		<div
			className={`prompt__wrapper prompt__wrapper${landerId}--v${versionId}`}
		>
			{title && (
				<h1
					className={`prompt__wrapper--title prompt__wrapper${landerId}--v${versionId}--title`}
				>
					{title}
				</h1>
			)}
			{image && (
				<div
					className={`prompt__wrapper__imageContainer prompt__wrapper${landerId}--v${versionId}__imageContainer`}
				>
					<img
						className={`prompt__wrapper__imageContainer--image prompt__wrapper${landerId}--v${versionId}__imageContainer--image`}
						src={image}
						alt={title}
					/>
					<div
						className={`prompt__wrapper__imageContainer--overlay prompt__wrapper${landerId}--v${versionId}__imageContainer--overlay`}
					/>

					{overlayTitle && (
						<h2
							className={`prompt__wrapper__imageContainer--overlayTitle prompt__wrapper${landerId}--v${versionId}__imageContainer--overlayTitle`}
						>
							{overlayTitle}
						</h2>
					)}
				</div>
			)}
			<div
				className={`prompt__wrapper--buttons prompt__wrapper${landerId}--v${versionId}--buttons`}
			>
				<Button variant="outline" onClick={handleNext}>
					{rejectText}
				</Button>
				<Button onClick={handleNext}>{acceptText}</Button>
			</div>
		</div>
	);
};
