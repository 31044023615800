import { useOutletContext } from 'react-router-dom';

import { RadioGroupHorizontal } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const LookingFor = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const lookingFor = Content[landerId][versionId]?.lookingFor;

	return (
		<RadioGroupHorizontal
			options={lookingFor?.options ?? []}
			name={lookingFor?.name ?? ''}
		/>
	);
};
