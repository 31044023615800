import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, ISelectProps, Select } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { Content } from '@/pages/content';

interface FormValues {
	gender: string;
}

export const SelectGender = () => {
	const { t } = useTranslation();
	const { landerId, versionId, handleNext }: OutletContext = useOutletContext();
	const selectGender =
		Content[landerId][versionId]?.selectGender ?? ({} as ISelectProps);

	const {
		setValue,
		handleSubmit,
		register,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			gender: '',
		},
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onChange = (value: string) => {
		clearErrors();
		setValue('gender', value);
	};

	const onSubmit = (data: FormValues) => {
		const error = !data[selectGender?.name as keyof FormValues];
		if (error) {
			return setError(selectGender?.name as keyof FormValues, {
				message: t('general.required'),
			});
		}
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<div className={`selectGender__wrapper${landerId}-v${versionId}`}>
			<h1 className={`selectGender__wrapper${landerId}-v${versionId}--title`}>
				{selectGender?.title}
			</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Select
					{...register(selectGender?.name as keyof FormValues)}
					options={selectGender?.options}
					placeholder={selectGender?.placeholder}
					onChange={onChange}
				/>
				<Expand expanded={Boolean(errors?.gender)}>
					<p className={'error-message'}>{errors?.gender?.message}</p>
				</Expand>
				<Button>{t('general.next')}</Button>
			</form>
		</div>
	);
};
