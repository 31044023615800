import './style.scss';

import { motion, useAnimation } from 'framer-motion';
import { useEffect, useMemo } from 'react';

import { useWindowSize } from '@/hooks';

interface IProgressBar {
	percent: number;
	duration?: number;
	delay?: number;
	easing?: string;
	barWidth?: number;
	barHeight?: number;
	progressColor?: string;
	baseColor?: string;
	hidePercentage?: boolean;
}

export const ProgressBar = ({
	percent,
	duration = 1,
	delay = 0,
	easing = 'easeInOut',
	barWidth = 613,
	barHeight = 5,
	progressColor = '#BE4D53',
	baseColor = '#debebe66',
	hidePercentage,
}: IProgressBar) => {
	const controls = useAnimation();
	const { width } = useWindowSize();
	const calculatedBarWidth = width
		? width <= 768
			? width - 45
			: Math.min(barWidth, width - 104) // 104 is padding on container
		: 0;

	const transition = useMemo(
		() => ({
			duration: duration,
			delay: delay,
			ease: easing,
		}),
		[duration, delay, easing]
	);

	const barVariants = useMemo(
		() => ({
			enter: {
				x: '-100%',
			},
			animate: {
				x: (percent - 100) * (calculatedBarWidth / 100),
				transition,
			},
		}),
		[percent, calculatedBarWidth, transition]
	);

	const percentVariants = useMemo(
		() => ({
			initial: {
				opacity: 0,
				y: 10,
			},
			animate: {
				opacity: 1,
				y: 0,
				transition: { ...transition, duration: 0.5 },
			},
		}),
		[transition]
	);

	useEffect(() => {
		controls.start({
			x: (percent - 100) * (calculatedBarWidth / 100),
			transition,
		});
	}, [percent, controls, transition, calculatedBarWidth]);

	return (
		<div className="progress__bar__wrapper">
			{!hidePercentage && (
				<motion.div
					key={percent}
					initial="initial"
					animate="animate"
					exit="initial"
					variants={percentVariants}
					className="progress__bar__wrapper__bar--percent"
				>
					{percent}%
				</motion.div>
			)}
			<div
				style={{
					width: calculatedBarWidth,
					height: barHeight,
					background: baseColor,
				}}
				className="progress__bar__wrapper__bar"
			>
				<motion.div
					variants={barVariants}
					initial="enter"
					animate={controls}
					exit="enter"
					style={{ background: progressColor }}
					className="progress__bar__wrapper__bar--filling"
				/>
			</div>
		</div>
	);
};
