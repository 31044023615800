import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, ISelectProps, Select } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { Content } from '@/pages/content';
import { generateNumbers } from '@/utility';

interface FormValues {
	minAge: string;
	maxAge: string;
}

export const SelectWomanAge = () => {
	const { t } = useTranslation();
	const { landerId, versionId, handleNext }: OutletContext = useOutletContext();
	const selectWomanAge = Content[landerId][versionId]?.selectWomanAge as {
		title: string;
		minAge: ISelectProps;
		maxAge: ISelectProps;
	};

	const {
		setValue,
		handleSubmit,
		register,
		setError,
		clearErrors,
		watch,
		formState: { errors },
	} = useForm<FormValues>();
	const { actions, state } = useStateMachine({ updateFormActionLander });
	const minAgeValue = watch('minAge');
	const maxAgeValue = watch('maxAge');

	const onChange = (name: keyof FormValues, value: string) => {
		clearErrors(name);
		setValue(name, value);
	};

	const onSubmit = (data: FormValues) => {
		const fieldNames: (keyof FormValues)[] = ['minAge', 'maxAge'];
		let hasError = false;

		fieldNames.forEach((fieldName) => {
			const field = selectWomanAge?.[fieldName];
			if (!data[field?.name as keyof FormValues]) {
				setError(field?.name as keyof FormValues, {
					message: t('general.required'),
				});
				hasError = true;
			}
		});

		if (hasError) return;

		const womanPreference = `${data.minAge}-${data.maxAge}`;
		actions.updateFormActionLander({
			lander: { ...state.lander, womanPreference },
		});
		handleNext();
	};

	return (
		<div
			className={`selectGender__wrapper selectGender__wrapper${landerId}-v${versionId}`}
		>
			<h1
				className={`selectGender__wrapper${landerId}-v${versionId}--title selectGender__wrapper--title `}
			>
				{selectWomanAge?.title}
			</h1>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={`selectGender__wrapper--form selectGender__wrapper${landerId}-v${versionId}--form`}
			>
				<div
					className={`selectGender__wrapper--form--container selectGender__wrapper${landerId}-v${versionId}--form--container`}
				>
					<div className="w-full">
						<Select
							{...register(selectWomanAge?.minAge?.name as keyof FormValues)}
							options={generateNumbers(18, Number(maxAgeValue ?? 99))
								.slice(0, -1)
								.map((value) => ({
									label: value.toString(),
									value: value.toString(),
									placeholder: `${t(
										'userPages.browseProfiles.from'
									)} ${value.toString()}`,
								}))}
							placeholder={selectWomanAge?.minAge?.placeholder}
							onChange={(value) =>
								onChange(
									selectWomanAge?.minAge?.name as keyof FormValues,
									value
								)
							}
						/>
						<Expand expanded={Boolean(errors?.minAge)}>
							<p className={'error-message'}>{errors?.minAge?.message}</p>
						</Expand>
					</div>
					<div className="w-full">
						<Select
							{...register(selectWomanAge?.maxAge?.name as keyof FormValues)}
							options={generateNumbers(Number(minAgeValue ?? 18), 99)
								.slice(1)
								.map((value) => ({
									label: value.toString(),
									value: value.toString(),
									placeholder: `${t(
										'userPages.browseProfiles.to'
									)} ${value.toString()}`,
								}))}
							placeholder={selectWomanAge?.maxAge?.placeholder}
							onChange={(value) =>
								onChange(
									selectWomanAge?.maxAge?.name as keyof FormValues,
									value
								)
							}
						/>
						<Expand expanded={Boolean(errors?.maxAge)}>
							<p className={'error-message'}>{errors?.maxAge?.message}</p>
						</Expand>
					</div>
				</div>
				<Button>{t('general.next')}</Button>
			</form>
		</div>
	);
};
