import { MarketEnum } from '@/model';

export const getZipCodeLength = () => {
	const market = import.meta.env.VITE_MARKET;
	switch (market) {
		case MarketEnum.CASUAL_PLEASURE:
		case MarketEnum.US:
			return 5;
		case MarketEnum.DK:
			return 4;
		default:
			return 5;
	}
};
