import { Market } from '@/model';

import { Content as ContentCP } from './casualPleasure';
import { Content as ContentDK } from './dk';
import { Content as ContentUS } from './us';

const market = import.meta.env.VITE_MARKET as Market;

const CONFIG = {
	dk: ContentDK,
	casualPleasure: ContentCP,
	us: ContentUS,
};

export const Content = CONFIG[market];
