import { yupResolver } from '@hookform/resolvers/yup';
import { TOptions } from 'i18next';
import * as yup from 'yup';

import { getZipCodeLength } from '@/utility';

import { city } from './common';

export function getValidationSchema(
	t: (key: string, options?: TOptions | string) => string
) {
	return yup.object().shape({
		zipCode: yup
			.string()
			.required(t('general.required'))
			.matches(/^\d+$/, t('zipCode.onlyDigits'))
			.min(
				getZipCodeLength(),
				t('zipCode.zipCodeLength', { length: getZipCodeLength() })
			)
			.max(
				getZipCodeLength(),
				t('zipCode.zipCodeLength', { length: getZipCodeLength() })
			),
		city: city,
	});
}

export const ZipCodeValidation = (t: (key: string) => string) =>
	yupResolver(getValidationSchema(t));
