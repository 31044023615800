import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AnimatedCounter, Button } from '@/components';
import { OutletContext } from '@/model';
import { generateRandomIntegerRange } from '@/utility';

import { Content } from '../content';

export const Matches = () => {
	const { t } = useTranslation();
	const { handleNext, landerId, versionId }: OutletContext = useOutletContext();
	const matches = Content[landerId][versionId]?.matches;

	return (
		<div className={`lander${landerId}__wrapper__container__matches__wrapper`}>
			<div className="flex flex-col gap-3.5 items-center">
				<AnimatedCounter value={generateRandomIntegerRange(20100, 30123)} />
				<div className="flex justify-center items-center translate-x-[40px]">
					{matches?.map((image, index) => {
						const middleIndex = Math.floor(matches.length / 2);
						const isMiddle = index === middleIndex;
						const isFirst = index === 0;
						const isLast = index === matches.length - 1;
						return (
							<img
								key={image}
								src={image}
								alt="match"
								className={`lander${landerId}__wrapper__container__matches__wrapper--image ${isMiddle ? 'middle' : ''}`}
								style={{
									zIndex: isFirst || isLast ? 0 : index,
									transform: `translateX(${index * -20}px)`,
								}}
							/>
						);
					})}
				</div>
			</div>
			<Button onClick={handleNext}>{t('general.next')}</Button>
		</div>
	);
};
