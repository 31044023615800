// Version 1
import version1Bg from './version1/bg.png';
import version1GenderFemale from './version1/gender/female.png';
import version1GenderMale from './version1/gender/male.png';
import version1Host from './version1/host.png';
import version1LookingForMen from './version1/lookingFor/men.png';
import version1LookingForWomen from './version1/lookingFor/women.png';
import version1Matches1 from './version1/matches/1.png';
import version1Matches2 from './version1/matches/2.png';
import version1Matches3 from './version1/matches/3.png';
import version1Matches4 from './version1/matches/4.png';
import version1Matches5 from './version1/matches/5.png';
import version1RelationshipTypeCasual from './version1/relationshipType/casual.png';
import version1RelationshipTypeCoupleOfDates from './version1/relationshipType/coupleOfDates.png';
import version1RelationshipTypeSeriousRelationship from './version1/relationshipType/seriousRelationship.png';
import version1Secret from './version1/secret/private.png';
import version1WomanPreference1829 from './version1/womanPreference/18-29.png';
import version1WomanPreference3045 from './version1/womanPreference/30-45.png';
import version1WomanPreference45 from './version1/womanPreference/45+.png';
import version1WomanTypeAthletic from './version1/womanType/athletic.png';
import version1WomanTypeCurvy from './version1/womanType/curvy.png';
import version1WomanTypeLarge from './version1/womanType/large.png';

export const Lander10Version1 = {
	bg: version1Bg,
	gender: {
		female: version1GenderFemale,
		male: version1GenderMale,
	},
	host: version1Host,
	womanType: {
		athletic: version1WomanTypeAthletic,
		curvy: version1WomanTypeCurvy,
		large: version1WomanTypeLarge,
	},
	relationshipType: {
		casual: version1RelationshipTypeCasual,
		coupleOfDates: version1RelationshipTypeCoupleOfDates,
		seriousRelationship: version1RelationshipTypeSeriousRelationship,
	},
	lookingFor: {
		men: version1LookingForMen,
		women: version1LookingForWomen,
	},
	womanPreference: {
		'18-29': version1WomanPreference1829,
		'30-45': version1WomanPreference3045,
		'45+': version1WomanPreference45,
	},
	secret: {
		image: version1Secret,
	},
	matches: [
		version1Matches1,
		version1Matches2,
		version1Matches3,
		version1Matches4,
		version1Matches5,
	],
};
