import { useOutletContext } from 'react-router-dom';

import { IPrompt, Prompt } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Condom = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const condom = Content[landerId][versionId]?.condom ?? ({} as IPrompt);

	return (
		<Prompt
			acceptText={condom?.acceptText}
			rejectText={condom?.rejectText}
			title={condom?.title}
		/>
	);
};
