import { GlobalState } from 'little-state-machine';

export function updateFormActionLander(
	state: GlobalState,
	payload: {
		lander: {
			gender: string;
			age: string;
			womanType: string;
			preference: string;
			email: string;
			username: string;
			password: string;
			city: string;
			zipCode: string;
			relationshipType: string;
			womanPreference: string;
			lookingFor: string;
			secret: string;
		};
	}
): GlobalState {
	return {
		...state,
		...payload,
	};
}
