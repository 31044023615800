import { PageConfig } from '@/model';

export const config: PageConfig = {
	9: {
		1: {
			pages: [
				{ id: 1, name: 'Disclaimer', url: 'disclaimer' },
				{ id: 2, name: 'Pictures', url: 'pictures' },
				{ id: 3, name: 'Secret', url: 'secret' },
				{ id: 4, name: 'Fun', url: 'fun' },
				{ id: 5, name: 'Condom', url: 'condom' },
				{ id: 6, name: 'Old', url: 'old' },
				{ id: 7, name: 'Gender Select', url: 'select-gender' },
				{ id: 8, name: 'Select Woman Age', url: 'select-woman-age' },
				{ id: 9, name: 'Select Age', url: 'select-age' },
				{ id: 10, name: 'Zip code', url: 'zip-code' },
				{ id: 11, name: 'Select Height', url: 'select-height' },
				{ id: 12, name: 'Email', url: 'email' },
				{ id: 13, name: 'Success', url: 'success' },
			],
			messages: {},
			backButtonScript: true,
		},
	},
	10: {
		1: {
			pages: [
				{ id: 1, name: 'Relationship Type', url: 'relationship-type' },
				{ id: 2, name: 'Gender', url: 'gender' },
				{ id: 3, name: 'Looking for', url: 'looking-for' },
				{ id: 4, name: 'Age', url: 'age' },
				{ id: 5, name: 'Type of woman', url: 'woman-type' },
				{ id: 6, name: 'Woman Preference', url: 'woman-preference' },
				{ id: 7, name: 'Zip code', url: 'zip-code' },
				{ id: 8, name: 'Secret', url: 'secret' },
				{ id: 9, name: 'Username', url: 'username' },
				{ id: 9, name: 'Password', url: 'password' },
				{ id: 10, name: 'Matches', url: 'matches' },
				{ id: 11, name: 'Email', url: 'email' },
				{ id: 12, name: 'Success', url: 'success' },
			],
			messages: {
				'relationship-type':
					'Hey, I’m Alice, let’s find you some women. What kind of relationship are you looking for?',
				gender: 'Are you a man or a woman?',
				'looking-for': 'Who are you looking for?',
				age: 'Type your age',
				'woman-type': 'What woman’s body type do you prefer?',
				'woman-preference':
					'Great. I would like to look for the most suitable matches. What woman’s age do you prefer?',
				'zip-code':
					'In which radius would you like to search? Just enter the Zip Code of your city. You can set the radius later. Where do you live?',
				secret:
					'The women you’re about to see are real and might live near you, you might even know them, you must agree to be discrete and private when using this site. Do you agree?',
				username: 'Please create a username',
				password: 'Great. Now choose a password.',
				matches: 'Wonderful! I have already found a lot of matches for you.',
				email:
					'Let me know your email so I can keep you updated about new matches!',
				success: 'Congratulations, all steps are completed!',
			},
			backButtonScript: true,
		},
	},
	15: {
		1: {
			pages: [
				{ id: 1, name: 'Relationship Type', url: 'relationship-type' },
				{ id: 2, name: 'Gender', url: 'gender' },
				{ id: 3, name: 'Looking for', url: 'looking-for' },
				{ id: 4, name: 'Age', url: 'age' },
				{ id: 5, name: 'Type of woman', url: 'woman-type' },
				{ id: 6, name: 'Zip code', url: 'zip-code' },
				{ id: 7, name: 'Username', url: 'username' },
				{ id: 8, name: 'Password', url: 'password' },
				{ id: 9, name: 'Email', url: 'email' },
				{ id: 10, name: 'Success', url: 'success' },
			],
			messages: {
				'relationship-type':
					"Hi...My name is Maria, and I want to take you through interesting journey with sexy questions... And guess what! You will connect with plenty of sexy women. Let's start! What do you want?",
				gender:
					'That’s nice! I am also into it! Now tell me are you a man or a woman?',
				'looking-for': 'Mmm, interesting! Are you interested in:',
				age: 'Well that`s sexy! Great! Now tell me sweetie, how old are you?',
				'woman-type': 'Neat! What woman’s body type do you prefer',
				'zip-code': 'Lovely! Where do you live?',
				username: 'We are near here... Please enter your Username',
				password: 'Cool Username! And how about your password?',
				email:
					'Well done! Enter your email and I will inform you about new matches now.',
				success: 'Congratulations, all steps are completed!',
			},
			backButtonScript: true,
		},
	},
};
