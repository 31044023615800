import { useQuery } from '@tanstack/react-query';

import { User } from '@/model';
import { axiosInstance } from '@/service';
import { LocalStorage, LocalStorageItems } from '@/utility';

const key = ['REGISTER_USER'];

export const useRegister = (registerData: Partial<User>) => {
	return useQuery({
		queryKey: [...key, registerData],
		queryFn: async () => {
			const { data } = await axiosInstance.post(
				'auth/member/register',
				registerData
			);
			if (data?.access_token) {
				const lander_id = LocalStorage.getItem(LocalStorageItems.LanderId);
				if (!lander_id?.includes('_typed_email')) {
					LocalStorage.setItem(
						LocalStorageItems.AccessToken,
						data.access_token
					);

					window.location.href = `${import.meta.env.VITE_REDIRECT_URL}?accessToken=${data?.access_token}`;
					return;
				}
				LocalStorage.setItem(LocalStorageItems.Email, data?.user?.email);
				window.location.href = `${import.meta.env.VITE_REDIRECT_URL}/email-verification?email=${data?.user?.email}`;
			}
			return data;
		},
		enabled: Object.values(registerData).length > 0,
	});
};
