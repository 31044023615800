import { useOutletContext } from 'react-router-dom';

import { Info } from '@/components';
import { IInfo } from '@/components/info/Info';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Disclaimer = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const disclaimer = Content[landerId][versionId]?.disclaimer ?? ({} as IInfo);

	return (
		<Info
			title={disclaimer?.title}
			description={disclaimer?.description}
			acceptText={disclaimer?.acceptText}
		/>
	);
};
