import { Lander9Version1 } from '@/assets/images/Lander9';
import { Lander10Version1 } from '@/assets/images/Lander10';
import { Lander15Version1 } from '@/assets/images/Lander15';
import { Content as IContent, OrientationEnum } from '@/model';

export const Content: IContent = {
	9: {
		1: {
			lander: {
				slider: Lander9Version1.slider,
				logo: Lander9Version1?.logo,
			},
			disclaimer: {
				title: 'This is NOT a dating site.',
				description:
					'Before we can show you a list and photos of women who are ready to have sexual contact right now, we need to ask a few  quick questions.',
				acceptText: 'ok',
			},
			pictures: {
				title:
					'These women want to share their nude private pictures with you. Do you accept?',
				acceptText: 'yes',
				rejectText: 'NO',
			},
			secret: {
				title:
					'Many of these women are desperate single moms and cheating wives looking for some fun. They could be your neighbors or someone you know. Do you agree to keep the identity of these women a secret?',
				acceptText: 'yes',
				rejectText: 'NO',
			},
			fun: {
				title:
					'These women have asked us to not allow men who are seeking a ‘relationship’. They only want to play. Are you comfortable with this?',
				acceptText: 'yes',
				rejectText: 'NO',
			},
			condom: {
				title: 'Do you agree to use condom when having sex?',
				acceptText: 'yes',
				rejectText: 'NO',
			},
			old: {
				title:
					'Are you at least 24 years old? These women have requested contacts from older men only.',
				acceptText: 'yes',
				rejectText: 'NO',
			},
			selectGender: {
				name: 'gender',
				placeholder: 'Choose gender',
				title: "I'm a:",
				options: [
					{
						value: 'male',
						label: 'Man',
					},
					{
						value: 'female',
						label: 'Woman',
					},
				],
			},
			selectWomanAge: {
				title: 'What is the ideal age of your new match?',
				minAge: {
					name: 'minAge',
					placeholder: 'Age from',
				},
				maxAge: {
					name: 'maxAge',
					placeholder: 'Age to',
				},
			},
			selectHeight: {
				name: 'height',
				title: 'How tall are you?',
				placeholder: 'Choose',
				options: [
					{ value: 'Shorter than 4\'7"', label: 'Shorter than 4\'7"' },
					{ value: 'From 4\'7" to 4\'11"', label: 'From 4\'7" to 4\'11"' },
					{ value: 'From 4\'11" to 5\'7"', label: 'From 4\'11" to 5\'7"' },
					{ value: 'From 5\'7" to 5\'11"', label: 'From 5\'7" to 5\'11"' },
					{ value: 'From 5\'11" to 6\'3"', label: 'From 5\'11" to 6\'3"' },
					{ value: 'From 6\'3" to 6\'7"', label: 'From 6\'3" to 6\'7"' },
					{ value: 'Taller than 6\'7"', label: 'Taller than 6\'7"' },
				],
			},
			success: {
				title: 'Congratulations, all steps are completed!',
			},
		},
	},
	10: {
		1: {
			lander: {
				bgImage: Lander10Version1.bg,
				hostImage: Lander10Version1.host,
				progressBarProgressColor: '#9f0220',
				progressBarWidth: 650,
			},
			relationshipType: {
				name: 'relationshipType',
				options: [
					{
						name: 'relationshipType',
						value: 'casual sex',
						label: 'casual sex',
						image: Lander10Version1.relationshipType.casual,
					},
					{
						name: 'relationshipType',
						value: 'couple of dates',
						label: 'couple of dates',
						image: Lander10Version1.relationshipType.coupleOfDates,
					},
					{
						name: 'relationshipType',
						value: 'serious relationship',
						label: 'serious relationship',
						image: Lander10Version1.relationshipType.seriousRelationship,
					},
				],
			},
			lookingFor: {
				name: 'lookingFor',
				options: [
					{
						name: 'lookingFor',
						value: 'male',
						label: 'men',
						image: Lander10Version1.lookingFor.men,
					},
					{
						name: 'lookingFor',
						value: 'female',
						label: 'women',
						image: Lander10Version1.lookingFor.women,
					},
				],
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'man',
						image: Lander10Version1.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'woman',
						image: Lander10Version1.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'athletic',
						label: 'athletic',
						image: Lander10Version1.womanType.athletic,
					},
					{
						name: 'womanType',
						value: 'curvy',
						label: 'curvy',
						image: Lander10Version1.womanType.curvy,
					},
					{
						name: 'womanType',
						value: 'large',
						label: 'large',
						image: Lander10Version1.womanType.large,
					},
				],
			},
			womanPreference: {
				name: 'womanPreference',
				options: [
					{
						name: 'womanPreference',
						value: '18-29',
						label: '18-29',
						image: Lander10Version1.womanPreference['18-29'],
					},
					{
						name: 'womanPreference',
						value: '30-45',
						label: '30-45',
						image: Lander10Version1.womanPreference['30-45'],
					},
					{
						name: 'womanPreference',
						value: '45+',
						label: '45+',
						image: Lander10Version1.womanPreference['45+'],
					},
				],
			},
			secret: {
				image: Lander10Version1.secret.image,
				overlayTitle: 'Warning!',
				rejectText: 'NO, I’M NOT READY',
				acceptText: 'YES, I’M READY',
			},
			matches: Lander10Version1.matches,
		},
	},
	15: {
		1: {
			lander: {
				bgImage: Lander15Version1.bg,
				hostImage: Lander15Version1.host,
				progressBarBaseColor: 'rgba(222, 190, 190, 0.40)',
				progressBarProgressColor: '#84215d',
				progressBarWidth: 1150,
			},
			relationshipType: {
				name: 'relationshipType',
				orientation: OrientationEnum.HORIZONTAL,
				options: [
					{
						name: 'relationshipType',
						value: 'casual sex',
						label: 'casual sex',
						image: Lander15Version1.relationshipType.casual,
					},
					{
						name: 'relationshipType',
						value: 'couple of dates',
						label: 'couple of dates',
						image: Lander15Version1.relationshipType.coupleOfDates,
					},
					{
						name: 'relationshipType',
						value: 'serious relationship',
						label: 'serious relationship',
						image: Lander15Version1.relationshipType.seriousRelationship,
					},
				],
			},
			lookingFor: {
				name: 'lookingFor',
				options: [
					{
						name: 'lookingFor',
						value: 'male',
						label: 'men',
						image: Lander15Version1.lookingFor.men,
					},
					{
						name: 'lookingFor',
						value: 'female',
						label: 'women',
						image: Lander15Version1.lookingFor.women,
					},
				],
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'man',
						image: Lander15Version1.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'woman',
						image: Lander15Version1.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				orientation: OrientationEnum.HORIZONTAL,
				options: [
					{
						name: 'womanType',
						value: 'normal',
						label: 'normal',
						image: Lander15Version1.womanType.normal,
					},
					{
						name: 'womanType',
						value: 'curvy',
						label: 'curvy',
						image: Lander15Version1.womanType.curvy,
					},
					{
						name: 'womanType',
						value: 'large',
						label: 'large',
						image: Lander15Version1.womanType.large,
					},
				],
			},
		},
	},
};
