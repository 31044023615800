import 'rc-select/assets/index.css';
import './style.scss';

import RCSelect, { SelectProps } from 'rc-select';

import { ChevronDown } from '@/icons';

export interface ISelectProps extends Partial<SelectProps> {
	name: string;
	placeholder: string;
}

export const Select = ({ placeholder, options, ...props }: ISelectProps) => {
	return (
		<RCSelect
			options={options}
			placeholder={placeholder}
			{...props}
			suffixIcon={ChevronDown}
		/>
	);
};
