import { yupResolver } from '@hookform/resolvers/yup';
import { TOptions } from 'i18next';
import * as yup from 'yup';

export function getValidationSchema(
	t: (key: string, options?: TOptions | string) => string
) {
	return yup.object().shape({
		height: yup.string().required(t('general.required')),
	});
}

export const HeightValidation = (t: (key: string) => string) =>
	yupResolver(getValidationSchema(t));
