import './style.scss';

import { useOutletContext } from 'react-router-dom';

import { Button } from '@/components';
import { OutletContext } from '@/model';

export interface IInfo {
	title: string;
	description: string;
	acceptText: string;
}

export const Info = ({ title, description, acceptText }: IInfo) => {
	const { handleNext }: OutletContext = useOutletContext();

	return (
		<div className="info__wrapper">
			<div className="info__wrapper__content">
				<h1 className="info__wrapper__content--title">{title}</h1>
				<p className="info__wrapper__content--description">{description}</p>
			</div>
			<Button onClick={handleNext}>{acceptText}</Button>
		</div>
	);
};
